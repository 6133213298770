import React from 'react'
import './allinoneCSS.css'
const Policy = () => {
    return (
        <div>
            <div>
                <div className='container-fluid'>
                    <div className="container">
                        <h2>General</h2>
                        <p>This Privacy Policy describes how CurvyEgg processes Users’ personal information to perform the Services offered on the Website (“Services”).
                        </p>
                        <br />
                        <p>Users must read and expressly consent to the data treatment referred by this Privacy Policy before using the Services.
                        </p>

                        <h2>Purposes of the Processing and Legal Basis</h2>
                        <p>
                            <p className='italic' >CurvyEgg will process the personal data of the User of this Website for the following purposes:</p>
                            <p>
                                Maintenance, Development, and Management of Services: This includes operations related to the management of customers concerning contracts, orders, deliveries, and invoices, and managing unpaid invoices and possible disputes. The data processed for this purpose will be kept as long as the business relationship is maintained and, once it ends, during the legally established periods of conservation and prescription of responsibilities. The legal basis is the execution of a contract in which the User is a party.
                            </p>
                        </p>
                        <h3 className='sub sub-head'>Responding to Requests for Information and/or Queries</h3>
                        <p className='sub sub-para'>
                            The data processed for this purpose will be kept until the request has been answered and, after that, during the legally established periods of conservation and limitation of responsibilities. The legal basis is the legitimate interest of CurvyEgg in responding to the User.
                        </p>
                        <h3 className='sub sub-head'>Keeping Users Informed</h3>
                        <p className='sub sub-para'>
                            <p>
                                This includes informing Users, including by electronic means, about CurvyEgg services and news. The data processed for this purpose will be kept until the User withdraws consent and, after that, during the legally established periods of conservation and limitation of responsibilities. The legal basis is the consent of the User.
                            </p>
                            <p>
                                Failure to accept this Privacy Policy will imply that all Services and Website content offered by CurvyEgg shall not be made available, and that the system subscription process shall be interrupted or terminated.
                            </p>

                        </p>



                        <h2>Categories of Data</h2>
                        <p>
                            <p>Users must complete all required field forms with truthful, complete, and up-to-date information, except for details where completion is indicated as optional. Otherwise, CurvyEgg reserves the right not to provide the Services.</p>

                            <p>Users guarantee that the personal details given to CurvyEgg are true and are responsible for notifying any modifications in these details by editing the information on the platform or informing CurvyEgg.</p>
                        </p>

                        <h2>Automated Decision-Making</h2>
                        <p>
                            CurvyEgg informs Users that by using the Services, they will be subject to automated decision-making, including profiling. The aim of this treatment is the adequacy of the listed purposes named herein.
                        </p>
                        <h2>Recipients and Personal Data Transfers</h2>
                        <p className='italic'>The data may be communicated to the following third-party recipient:</p>
                        <p>
                            <ul>
                                <li>Public Administrations for the fulfillment of legal obligations and banking institutions for the management of collections and payments.</li>
                                <li>Providers of electronic communications, office automation, hosting, housing, computer maintenance, management, accounting, auditing, consultancy, and legal representation. These providers may be located outside the European Economic Area, in which case CurvyEgg will have previously adopted the appropriate safeguards.</li>
                            </ul>
                        </p>


                        <h2> Rights of the Users</h2>

                        <p>
                            <p>Users are entitled to exercise their rights of access, rectification, erasure, restriction of processing, data portability, not to be subject to a decision based solely on automated processing, including profiling, and objection, by contacting CurvyEgg and sending a written notification to legal………………….. attaching a copy of their National Identity Document or another equivalent identity document identifying them as a User.
                            </p>
                            <p>Users have the right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal. Users also have the right to lodge a complaint with a supervisory authority.</p>
                        </p>


                        <h2> CurvyEgg as Data Processor</h2>

                        <p>
                            <p>In the event that the User purchases a license to use the Services, CurvyEgg will need to process certain personal data on behalf of the licensee (whether the licensee is the User itself or a legal entity represented by the User). For these purposes, the User shall be considered the Data Controller and CurvyEgg shall be considered the Data Processor.
                            </p>
                            <p>The Data Processor shall process the personal data necessary to carry out the Services on behalf of the Controller. The processing shall have a duration equal to that of the provision of the Services, in such a way that once the provision of the Services has been completed, the processing shall be deemed to have been completed.</p>
                        </p>

                        <h3 className='sub sub-head'> Identification of the Information Concerned</h3>
                        <p className='italic sub'>For the performance of the Services, the Controller shall make available to the Processor the information described below:</p>
                        <p className='sub sub-para'>
                            <ul>
                                <li>Data of an identifying nature</li>
                                <li>Personal characteristics data</li>
                                <li>Data on social circumstances</li>
                                <li>Academic and professional data</li>
                                <li>Employment details</li>
                                <li>Economic, financial and insurance details</li>
                                <li>Transactions in goods and services data</li>
                                <li>Health data</li>
                                <li>Data revealing racial or ethnic origin</li>
                                <li>Data revealing political opinions</li>
                                <li>Data revealing religious or philosophical convictions</li>
                                <li>Data concerning sex life or sexual orientation</li>
                            </ul>
                        </p>
                        <h3 className='sub sub-head'> Obligations of the Processor</h3>
                        <p className='italic sub'>The Data Processor undertakes to:
                        </p>
                        <p className='sub sub-para'>
                            <ul>
                                <li>Only process data for service provision.</li>
                                <li>Adhere to Controller's instructions and notify of any breaches.</li>
                                <li>Maintain records of processing activities.</li>
                                <li>Only share data with third parties as authorized by the Controller.</li>
                                <li>Notify the Controller before subcontracting and ensure compliance.</li>
                                <li>Maintain confidentiality of data, even after service ends.</li>
                                <li>Ensure personnel respect confidentiality and are trained in data protection.</li>
                                <li>Keep compliance documentation available.</li>
                                <li>Help the Controller respond to data subject rights requests.</li>
                                <li>Inform the Controller within 48 hours of any data breaches, including relevant details.</li>
                                <li>Assist in data protection impact assessments.</li>
                                <li>Aid in consultations with supervisory authorities.</li>
                                <li>Supply information for compliance and audits.</li>
                                <li>Implement and regularly review data security measures.</li>
                                <li>Appoint and provide contact details if required.</li>
                                <li>Allow 30 days for data download post-service and delete data thereafter.</li>
                            </ul>
                        </p>

                        <h2>Security and Protection of Data
                        </h2>
                        <p>
                            CurvyEgg has adopted the data protection security measures legally required and strives to implement additional technical measures and means within its scope to avoid the loss, misuse, alteration, unauthorized access to, and theft of the personal details provided. CurvyEgg agrees to use all details sent by registered Users with the utmost confidentiality and resilience.
                            CurvyEgg's use and transfer of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements. Google Workspace APIs are not used to develop, improve, or train generalized AI and/or ML models.

                        </p>
                        <h2>Changes to this Privacy Policy
                        </h2>
                        <p>
                            CurvyEgg reserves the right to amend this policy to adapt it to new regulations, case laws, and industrial and/or commercial practices.

                        </p>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Policy