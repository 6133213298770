import React, { useState, useEffect } from 'react'
import '../WhyCurvy/whycurvy.css'
import './faq.css'
import '../../assets/fonts/font.css'

import ell1 from '../../assets/faqell1.png'
import ell2 from '../../assets/faqell2.png'
import Aos from 'aos'

function FAQ() {
    const [showone, setshowone] = useState(false)
    const [showtwo, setshowtwo] = useState(false)
    const [showthree, setshowthree] = useState(false)
    const [showfour, setshowfour] = useState(false)
    const [showfive, setshowfive] = useState(false)
    const [showseven, setshowseven] = useState(false)
    const [showsix, setshowsix] = useState(false)
    const [showeight, setshoweight] = useState(false)
    const [shownine, setshownine] = useState(false)
    const [showten, setshowten] = useState(false)
    const [showeleven, setshoweleven] = useState(false)
    useEffect(
        () => Aos.init({
            duration: 1000
        })
    )

    const showTab = (id) => {
        if (id === 'one') {
            setshowone(!showone);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)


        }
        else if (id === 'two') {
            setshowone(false);
            setshowtwo(!showtwo);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)
        }
        else if (id === 'three') {
            setshowthree(!showthree)
            setshowone(false);
            setshowtwo(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)
        }
        else if (id === 'four') {
            setshowfour(!showfour);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)
        }
        else if (id === 'five') {
            setshowfive(!showfive);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowseven(false);
            setshowsix(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)
        }
        else if (id === 'seven') {
            setshowseven(!showseven)
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowsix(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)
        }
        else if (id === 'six') {
            setshowsix(!showsix);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)

        }
        else if (id === 'eight') {
            setshowsix(false);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshoweight(!showeight)
            setshownine(false)
            setshowten(false)
            setshoweleven(false)

        }
        else if (id === 'nine') {
            setshowsix(false);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshoweight(false)
            setshownine(!shownine)
            setshowten(false)
            setshoweleven(false)

        }
        else if (id === 'ten') {
            setshowsix(false);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshoweight(false)
            setshownine(false)
            setshowten(!showten)
            setshoweleven(false)

        }
        else if (id === 'eleven') {
            setshowsix(false);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshoweight(false)
            setshownine(false)
            setshowten(false)
            setshoweleven(!showeleven)

        }
    }
    return (
        <div id="FAQ" data-aos="fade-up" className="FAQ_top">

            <div className="container">
                <h3 className="FAQ_main_head fontsforweb_fontid_9785">Frequently Asked Questions</h3>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div style={{ width: '100%', margin: '0 auto' }}>
                            <div className="nopurpose">
                                <div className="tab_main" style={{ overflow: 'hidden', border: showeight && 0 }}>

                                    <p onClick={() => showTab('eight')}>What is CurvyEgg? </p>
                                    <div style={{ overflow: 'hidden' }} className={showeight ? 'showtab' : 'hidetab'}><p className="main_para">CurvyEgg is a comprehensive platform that enables you to create and deploy AI chatbots without any coding skills, enhancing customer engagement and support.</p></div>
                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showone && 0 }}>

                                    <p onClick={() => showTab('one')}>How can CurvyEgg chatbots benefit my business?</p>
                                    <div style={{ overflow: 'hidden' }} className={showone ? 'showtab' : 'hidetab'}><p className="main_para">CurvyEgg chatbots can automate customer support, provide instant responses, capture leads, and personalize user experiences, leading to increased efficiency and customer satisfaction.</p></div>
                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showtwo && 0 }}>

                                    <p onClick={() => showTab('two')}>Do I need coding skills to use CurvyEgg?</p>
                                    <div style={{ overflow: 'hidden' }} className={showtwo ? 'showtab' : 'hidetab'}><p className="main_para">No, CurvyEgg is designed to be user-friendly and requires no coding skills. You can easily create and customize chatbots through our intuitive interface.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showthree && 0 }}>
                                    <p onClick={() => showTab('three')}>Which platforms can CurvyEgg integrate with?</p>
                                    <div style={{ overflow: 'hidden' }} className={showthree ? 'showtab' : 'hidetab'}><p className="main_para">CurvyEgg integrates seamlessly with popular platforms like Shopify, WordPress, Magento, and Joomla, among others.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showfour && 0 }}>
                                    <p onClick={() => showTab('four')}>How does CurvyEgg ensure data security?</p>
                                    <div style={{ overflow: 'hidden' }} className={showfour ? 'showtab' : 'hidetab'}><p className="main_para">CurvyEgg uses advanced encryption and security protocols to protect your data and ensure that all interactions are secure and confidential.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showfive && 0 }}>
                                    <p onClick={() => showTab('five')}>Can CurvyEgg chatbots handle multiple languages? </p>
                                    <div style={{ overflow: 'hidden' }} className={showfive ? 'showtab' : 'hidetab'}><p className="main_para">Yes, CurvyEgg chatbots are capable of supporting multiple languages, allowing you to engage with a global audience effectively.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showsix && 0 }}>

                                    <p onClick={() => showTab('six')}>What kind of customer support does CurvyEgg offer?</p>
                                    <div style={{ overflow: 'hidden' }} className={showsix ? 'showtab' : 'hidetab'}><p className="main_para">CurvyEgg provides 24/7 customer support through various channels, including live chat, email, and phone support, to assist you with any issues or questions.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showseven && 0 }}>
                                    <p onClick={() => showTab('seven')}>How can I get started with CurvyEgg?</p>
                                    <div style={{ overflow: 'hidden' }} className={showseven ? 'showtab' : 'hidetab'}><p className="main_para">Getting started is simple. Sign up on our website, follow the setup instructions, and you can begin creating your chatbot within minutes.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: shownine && 0 }}>
                                    <p onClick={() => showTab('nine')}>Can I customize the appearance of my chatbot?</p>
                                    <div style={{ overflow: 'hidden' }} className={shownine ? 'showtab' : 'hidetab'}><p className="main_para">Yes, CurvyEgg allows you to fully customize the appearance and functionality of your chatbot to match your brand identity.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showten && 0 }}>
                                    <p onClick={() => showTab('ten')}>How does CurvyEgg handle complex customer queries?</p>
                                    <div style={{ overflow: 'hidden' }} className={showten ? 'showtab' : 'hidetab'}><p className="main_para">CurvyEgg's AI-powered chatbots use advanced natural language processing (NLP) to understand and respond to complex queries accurately and efficiently.</p></div>

                                </div>
                                <div className="tab_main" style={{ overflow: 'hidden', border: showeleven && 0 }}>
                                    <p onClick={() => showTab('eleven')}>Ready to Increase Your Sales and Customer Satisfaction?</p>
                                    <div style={{ overflow: 'hidden' }} className={showeleven ? 'showtab' : 'hidetab'}><p className="main_para">Boost sales and enhance customer satisfaction with our innovative solutions. Achieve seamless integration and outstanding results.</p></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <img className="img1" style={{ position: 'absolute', top: '20px', zIndex: -2 }} src={ell1} width="294px" height="326px" />
            <img className="img2" src={ell2} />
        </div>
    )
}

export default FAQ
