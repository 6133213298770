import React, { useState, useEffect } from 'react'

import './aboutcurvy.css'
import '../../assets/fonts/font.css'

import { Button, ButtonGroup } from 'react-bootstrap'

import icon1 from '../../assets/svg/about-svg/product-01.svg';
import icon2 from '../../assets/svg/about-svg/custmor-support-01.svg';
import icon3 from '../../assets/svg/about-svg/FAQs-Assistance-01.svg';

import comm_mob from '../../assets/svg/about-svg/product-01-white.svg'
import coll_mob from '../../assets/svg/about-svg/FAQs-Assistance-01-white.svg'
import cont_mob from '../../assets/svg/about-svg/custmor-support-01-white.svg'

import Card from '../../UI/Card/Card'
import downarrow from '../../assets/svg/downarrow.svg'
import Aos from 'aos'
import { motion } from 'framer-motion'
import ThreeCards from '../../UI/ThreeCards/ThreeCards';
function AboutCurvy() {
    useEffect(
        () => Aos.init({
            duration: 2000,
            once: true
        }), []
    )
    const [cardClass, setcardClass] = useState('default')
    const [cardMobData] = useState({
        one: {
            imgsrc: comm_mob,
            head: 'Product Recommendations',
            para: `Utilize chatbots to suggest products in a friendly, conversational way based on the user's past searches, preferences, and purchase history.`,
        },
        two: {
            imgsrc: coll_mob,
            head: 'Customer Support',
            para: 'Deploy intelligent bots to evaluate issues, streamline resolutions, enhance service quality, and efficiently automate routine processes.'
        },
        three: {
            imgsrc: cont_mob,
            head: 'FAQs Assistance',
            para: 'Keep potential customers from getting lost on your website by having your bot answer FAQs instantly and accurately, enhancing their experience.'
        }
    })
    const [cardData] = useState({
        one: {
            imgsrc: icon1,
            head: 'Product Recommendations',
            para: `Utilize chatbots to suggest products in a friendly, conversational way based on the user's past searches, preferences, and purchase history.`
        },
        two: {
            imgsrc: icon2,
            head: 'Customer Support',
            para: 'Deploy intelligent bots to evaluate issues, streamline resolutions, enhance service quality, and efficiently automate routine processes'
        },
        three: {
            imgsrc: icon3,
            head: 'FAQs Assistance',
            para: 'Keep potential customers from getting lost on your website by having your bot answer FAQs instantly and accurately, enhancing their experience.'
        }
    });
    const [cardId, setCardId] = useState({
        communicate: false,
        collect: false,
        contribute: false
    })
    const [cardbtnclick, setCardbtnclick] = useState(false)
    const showMoreDetail = (id) => {
        if (id === 'communicate') {
            setCardId({
                collect: false,
                contribute: false,
                communicate: true
            })
        }
        else if (id === 'collect') {
            setCardId({
                collect: true,
                contribute: false,
                communicate: false
            })
        }
        else if (id === 'contribute') {
            setCardId({
                collect: false,
                contribute: true,
                communicate: false
            })
        }
        setCardbtnclick(!cardbtnclick);


    }

    let card_content = '';
    if (cardId.communicate) {
        card_content = (
            <div className="active">
                <Card cardDetail={cardData.one} cardReadMore={() => showMoreDetail('communicate')} handleclose={() => setCardbtnclick(!cardbtnclick)} btnClicked={cardbtnclick} />
            </div>
        )
    }
    else if (cardId.collect) {
        card_content = (
            <div className="active">
                <Card cardDetail={cardData.two} cardReadMore={() => showMoreDetail('collect')} btnClicked={cardbtnclick} handleclose={() => setCardbtnclick(!cardbtnclick)} />
            </div>
        )
    }
    else if (cardId.contribute) {
        card_content = (
            <Card cardDetail={cardData.three} cardReadMore={() => showMoreDetail('contribute')} btnClicked={cardbtnclick} handleclose={() => setCardbtnclick(!cardbtnclick)} />
        )
    }
    const [commvariant, setcommvariant] = useState(true)
    const [collvariant, setcollvariant] = useState(false)
    const [contvariant, setcontvariant] = useState(false)
    const movCard = (id) => {
        if (id === 'comm') {
            setcardClass('default');
            setcommvariant(true)
            setcollvariant(false)
            setcontvariant(false)
        }
        else if (id === 'coll') {
            setcardClass('collMove')

            setcommvariant(false)
            setcollvariant(true)
            setcontvariant(false)
        }
        if (id === 'cont') {
            setcardClass('contMove');
            setcommvariant(false)
            setcollvariant(false)
            setcontvariant(true)
        }
    }

    return (
        <div id="aboutcurvy" data-aos="fade-up" style={{ width: "100%", minheight: "820px", marginBottom: '30px', position: 'relative' }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="fstrow">
                            <h3 className="about_firstSec_head hidecurvy_on_web">About Curvy</h3>
                            <h4 className="about_firstSec_para">Versatile Tool for Every Need</h4>
                            <img className=" downimg hidecurvy_on_web" src={downarrow} />
                            <h3 className="about_firstSec_head ">How Can Chatbots Transform Your Business?</h3>
                            <p className="about_firstSec_subhead">CurvyEgg can revolutionize your customer service by providing instant, 24/7 support, enhancing customer satisfaction and loyalty. They also streamline operations by automating repetitive tasks, freeing up your team to focus on more strategic initiatives.</p>
                        </div>

                    </div>
                </div>
                <div className="mobilebtn">
                    <ButtonGroup className="btn-group" aria-label="Basic example">
                        <Button className="button" onClick={() => movCard('comm')} variant={commvariant ? 'primary' : 'light'}>  Product Recommendations</Button>
                        <Button className="button" onClick={() => movCard('coll')} variant={collvariant ? 'primary' : 'light'}>Customer Support</Button>
                        <Button className="button" onClick={() => movCard('cont')} variant={contvariant ? 'primary' : 'light'}>FAQs Assistance</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div style={{ width: '86%', margin: '50px auto' }} className='aboutthreecardhideonmob'>

                <ThreeCards />
            </div>
            <div className='container'>
                <div className="card_main" style={{ marginTop: '50px' }}>

                    {cardbtnclick ? <motion.div style={{ width: "100%" }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                        initial={{
                            opacity: 0,
                            y: '-100px'
                        }}
                    >{card_content}</motion.div> : (
                        null
                    )}
                    {!cardbtnclick ? <div data-aos-duration="1000" className={cardClass}>
                        <Card cardDetail={cardMobData.one} cardReadMore={() => showMoreDetail('communicate')} />
                    </div> : null}

                    {!cardbtnclick ? <div data-aos-duration="1000" className={cardClass}>
                        <Card cardDetail={cardMobData.two} cardReadMore={() => showMoreDetail('collect')} />
                    </div> : null}

                    {!cardbtnclick ? <div data-aos-duration="1000" className={cardClass}>
                        <Card cardDetail={cardMobData.three} cardReadMore={() => showMoreDetail('contribute')} />
                    </div> : null}
                </div>
            </div>
            {/* for mobile card will be in carousel + tab-navbar*/}
        </div>
    )
}

export default AboutCurvy
