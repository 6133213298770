import React from 'react'
import './allinoneCSS.css'
const Cookies = () => {
    return (
        <div className='container-fluid'>
            <div className="container">
                <h2>What Are Cookies?</h2>
                <p>Cookies and similar technologies are small text files or pieces of code that often contain a unique identification code. When you visit a website or use a mobile application, a computer asks your device for permission to save this file and access information. Information collected through cookies may include the date and time of the visit and how you use the website or application.
                </p>
                <h2>Why Do We Use Cookies?</h2>
                <p>Cookies ensure that you remain logged in during your visit, your shopping cart items are saved, you can shop safely, and the website runs smoothly. Cookies also help us understand how our website is used and how we can improve it. Additionally, our cookies may present you with targeted advertisements that match your interests.
                </p>
                <h2>Types of Cookies We Use</h2>
                <h3 className='sub italic'>Necessary Cookies:</h3>
                <p className='italic sub'>These cookies are essential for the website to function properly. They enable actions such as: </p>
                <ul className='sub'>
                    <li>Storing items in a shopping cart for online purchases</li>
                    <li>Saving your cookie preferences for this website</li>
                    <li>Storing language preferences</li>
                    <li>Checking if you are logged in to our portal</li>
                </ul>

                <h3 className='italic sub'>Performance Cookies:</h3>
                <p className='italic sub'>These cookies enable more functionality for visitors. They can be set by our external service providers or our own website and enable features such as:</p>
                <ul className='sub'>
                    <li>Live chat services</li>
                    <li>Watching online videos</li>
                    <li>Social media sharing buttons</li>
                    <li>Logging in with social media</li>
                </ul>
                <h3 className='sub italic'>Advertising/Tracking Cookies:</h3>
                <p className='sub'>These cookies are set by external advertising partners for profiling and tracking data across multiple websites. They allow us to show advertisements on other websites based on your user profile and preferences. They also track how many visitors have seen or clicked on our advertisements to optimize advertising campaigns.
                </p>
                <h3 className='italic sub'>Unclassified Cookies:</h3>
                <p className='sub'>You can opt out of all but necessary cookies. In your browser settings, you can change the settings to block cookies. Most browsers provide instructions on how to do this in the help section. However, blocking cookies may prevent you from enjoying all the technical features our website offers and may negatively affect your user experience.</p>
                <h2 className=''>How Can I Switch Off or Remove Cookies?</h2>
                <p className=''>You can opt out of all but necessary cookies. In your browser settings, you can change the settings to block cookies. Most browsers provide instructions on how to do this in the help section. However, blocking cookies may prevent you from enjoying all the technical features our website offers and may negatively affect your user experience.</p>
            </div>
        </div>
    )
}

export default Cookies