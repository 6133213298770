import React, { useState, useEffect } from 'react'

import analytics from '../../assets/lead-generation.jpg'
import research from '../../assets/team-dev.jpg'
import dashboard from '../../assets/agent.jpg'
import '../../assets/fonts/font.css'
import round from '../../assets/Round.png'
import Aos from 'aos';
import { motion } from 'framer-motion'
import './sayno.css'
import LazyLoad from 'react-lazyload'
function SayNo() {

    useEffect(
        () => (
            Aos.init({
                duration: 2000,
                once: true
            })
        )
    )
    const [data, setData] = useState({
        sales: {
            img: analytics,
            text: 'Engage your website visitors, qualify leads, and gather valuable information with our conversational forms. Simply create and customize a form to suit your needs, and start generating leads with ease!'
            , head: 'Conversational Forms for Lead Generation'
        },
        research: {
            img: research,
            text: 'Our expert chatbot development team, composed of skilled developers, designers, and testers, is dedicated to providing you with exceptional support. We guarantee top-quality chatbot development with outstanding user interfaces and user experiences.'
            , head: 'Expert Chatbot Development Team'
        },
        data: {
            img: dashboard,
            text: 'Improve your customer service and increase sales with our live chat feature. Easily switch to a human agent who can personally address and resolve customer queries quickly and efficiently, ensuring a positive customer experience every time.'
            , head: 'Live Chat with Agent Support'
        }
    })

    const [currData, setCurrData] = useState({
        img: analytics,
        text: 'Engage your website visitors, qualify leads, and gather valuable information with our conversational forms. Simply create and customize a form to suit your needs, and start generating leads with ease!'
        , head: 'Conversational Forms for Lead Generation'
    })

    const saleBtnworking = () => {
        setCurrData({
            ...data.sales
        })
    }
    const researchBtnworking = () => {
        setCurrData({
            ...data.research
        })
    }
    const dataBtnworking = () => {
        setCurrData({
            ...data.data
        })
    }
    return (
        <div id="agents" className='agents' style={{ width: '100%', minheight: '680px', marginTop: '50px', marginBottom: '50px', overflow: 'hidden' }}>
            <div className="row" style={{ marginTop: '80px' }}>
                <div className="col-md-7 col-sm-12" >
                    {/* <img src={analytics} style={{width:'95%',maxHeight:'557px'}}  /> */}
                    <div data-aos="fade-right" className="main_left_img">
                        <div className="main_left_img_inner_1">
                        </div>
                        <div className="main_left_img_inner_2"
                        >
                            <LazyLoad offset={100}>
                                {currData.head === 'Conversational Forms for Lead Generation' ? <motion.img className="main_left_img_inner_3" src={analytics}
                                    animate={{
                                        opacity: 1,
                                    }}
                                    initial={{
                                        opacity: 0,
                                    }} /> : null}
                            </LazyLoad>
                            <LazyLoad offset={100}>
                                {currData.head === 'Expert Chatbot Development Team' ? <motion.img className="main_left_img_inner_3" src={research}
                                    animate={{
                                        opacity: 1,
                                    }}
                                    initial={{
                                        opacity: 0,

                                    }} /> : null}
                            </LazyLoad>
                            <LazyLoad offset={100}>
                                {currData.head === 'Live Chat with Agent Support' ? <motion.img className="main_left_img_inner_3" src={dashboard}
                                    animate={{
                                        opacity: 1
                                    }}
                                    initial={{
                                        opacity: 0,

                                    }} /> : null}
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-sm-12">
                    <div data-aos="fade-left" className="main_say">

                        {currData.head === 'Conversational Forms for Lead Generation' ? <motion.div
                            animate={{
                                opacity: 1,

                            }}
                            initial={{
                                opacity: 0,

                            }}
                        >
                            <h3 className="main_say_head">{currData.head}</h3>
                            <p className="main_say_para">{currData.text}</p>
                        </motion.div> : null}
                        {currData.head === 'Expert Chatbot Development Team' ? <motion.div
                            animate={{
                                opacity: 1,

                            }}
                            initial={{
                                opacity: 0,

                            }}
                        >
                            <h3 className="main_say_head">{currData.head}</h3>
                            <p className="main_say_para">{currData.text}</p>
                        </motion.div> : null}
                        {currData.head === 'Live Chat with Agent Support' ? <motion.div
                            animate={{
                                opacity: 1,

                            }}
                            initial={{
                                opacity: 0,

                            }}
                        >
                            <h3 className="main_say_head">{currData.head}</h3>
                            <p className="main_say_para">{currData.text}</p>
                        </motion.div> : null}
                        <div className="main_btn_grp">
                            <button className={currData.head === 'Conversational Forms for Lead Generation' ? "button_focus" : null} onClick={() => saleBtnworking()}>Lead Generation</button>
                            <button className={currData.head === 'Expert Chatbot Development Team' ? "button_focus" : null} onClick={() => researchBtnworking()}>Development Team</button>
                            <button className={currData.head === 'Live Chat with Agent Support' ? "button_focus" : null} onClick={() => dataBtnworking()}>Agent Support</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SayNo
