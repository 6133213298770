import React, { useState, useEffect } from 'react'

import './whycurvy.css'
import ellipse_1 from '../../assets/faqell1.png'
import ellipse from '../../assets/faqell2.png'
import charts from '../../assets/Scripts_for_carts.png'
import '../../assets/fonts/font.css'
import curverec from '../../assets/curverec.png'
import Aos from 'aos'
import Whycards from '../../UI/whycards/Whycards'

function WhyCurvy() {
    const [showone, setshowone] = useState(false)
    const [showtwo, setshowtwo] = useState(false)
    const [showthree, setshowthree] = useState(false)
    const [showfour, setshowfour] = useState(false)
    const [showfive, setshowfive] = useState(false)
    const [showseven, setshowseven] = useState(false)
    const [showeight, setshoweight] = useState(false)
    const [showsix, setshowsix] = useState(false)
    const [showzero, setshowzero] = useState(true);

    const showTab = (id) => {
        if (id === 'zero') {
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshowzero(!showzero);
            setshoweight(false)

        }
        if (id === 'one') {
            setshowone(!showone);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshowzero(false)
            setshoweight(false)


        }
        if (id === 'two') {
            setshowone(false);
            setshowtwo(!showtwo);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshowzero(false)
            setshoweight(false)

        }
        if (id === 'three') {
            setshowthree(!showthree)
            setshowone(false);
            setshowtwo(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshowzero(false)
            setshoweight(false)

        }
        if (id === 'four') {
            setshowfour(!showfour);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfive(false);
            setshowseven(false);
            setshowsix(false);
            setshowzero(false)
            setshoweight(false)

        }
        if (id === 'five') {
            setshowfive(!showfive);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);

            setshowseven(false);
            setshowsix(false);
            setshowzero(false)
            setshoweight(false)

        }
        if (id === 'seven') {
            setshowseven(!showseven)
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowsix(false);
            setshowzero(false)
            setshoweight(false)

        }
        if (id === 'six') {
            setshowsix(!showsix);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowzero(false)
            setshoweight(false)
        }
        if (id === 'eight') {
            setshowsix(false);
            setshowone(false);
            setshowtwo(false);
            setshowthree(false);
            setshowfour(false);
            setshowfive(false);
            setshowseven(false);
            setshowzero(false)
            setshoweight(!showeight)

        }
    }
    return (
        <div id="whycurvy" className="whycurvy_top" style={{ position: 'relative' }} >
            <img style={{ position: 'absolute', zIndex: -1, top: '-10px', left: '-60px' }} src={ellipse_1} width="346px" height="346px" />
            <img style={{ position: 'absolute', right: '0', top: '380px', opacity: '1', zIndex: -1 }} src={ellipse} width="346px" height="346px" />
            <div><img src={curverec} className="lady_back" /></div>
            <div className="container">
                <h3 data-aos="fade-down" className="whycurvy_main_head fontsforweb_fontid_9785">Why<span style={{ color: 'black' }}>CurvyEgg<span style={{ fontFamily: 'sans-serif' }}>&#x3F;</span></span></h3>
                <div className="row">
                    <div data-aos="fade-down-right" className="col-md-5 col-sm-12" style={{ marginTop: '20px' }}>
                        <div className="nopurpose">
                            <div className="tab_main" style={{ overflow: 'hidden', border: showzero && 0 }}>

                                <p onClick={() => showTab('zero')}>Global Reach</p>
                                <div style={{ overflow: 'hidden' }} className={showzero ? 'showtab' : 'hidetab'}><p className="main_para">With CurvyEgg, you can connect with millions of users worldwide. Our extensive network allows your business to reach a global audience, expanding your market and increasing your potential customer base.</p></div>
                            </div>

                            <div className="tab_main" style={{ overflow: 'hidden', border: showone && 0 }}>

                                <p onClick={() => showTab('one')}>Business Growth </p>
                                <div style={{ overflow: 'hidden' }} className={showone ? 'showtab' : 'hidetab'}><p className="main_para">We are passionate about helping your business flourish. Our dedicated support team works tirelessly to ensure that your customer service is exceptional, leading to higher satisfaction rates and loyalty.</p></div>
                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showtwo && 0 }}>

                                <p onClick={() => showTab('two')}>Customized Solutions</p>
                                <div style={{ overflow: 'hidden' }} className={showtwo ? 'showtab' : 'hidetab'}><p className="main_para">We understand that every business is unique. Our services are tailored to meet your specific needs, ensuring that you receive the best support possible.</p></div>

                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showthree && 0 }}>
                                <p onClick={() => showTab('three')}>Expert Team</p>
                                <div style={{ overflow: 'hidden' }} className={showthree ? 'showtab' : 'hidetab'}><p className="main_para">Our team of experienced professionals is committed to providing top-notch support. We continuously train our staff to stay updated with the latest industry trends and technologies.</p></div>

                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showfour && 0 }}>
                                <p onClick={() => showTab('four')}>Cost-Effective</p>
                                <div style={{ overflow: 'hidden' }} className={showfour ? 'showtab' : 'hidetab'}><p className="main_para">By choosing CurvyEgg, businesses can significantly reduce their operational costs. Our efficient support system minimizes the need for extensive in-house customer service teams, allowing you to allocate resources more effectively.</p></div>

                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showfive && 0 }}>
                                <p onClick={() => showTab('five')}>Advanced Technology </p>
                                <div style={{ overflow: 'hidden' }} className={showfive ? 'showtab' : 'hidetab'}><p className="main_para">We leverage the latest advancements in technology, including AI and machine learning, to enhance our support services. This ensures faster response times, accurate issue resolution, and a seamless customer experience.</p></div>

                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showsix && 0 }}>

                                <p onClick={() => showTab('six')}>Comprehensive Analytics </p>
                                <div style={{ overflow: 'hidden' }} className={showsix ? 'showtab' : 'hidetab'}><p className="main_para">Understanding your customers is key to improving service. CurvyEgg offers detailed analytics and reporting, providing insights into customer interactions and helping you make data-driven decisions to enhance your service offerings.</p></div>

                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showseven && 0 }}>
                                <p onClick={() => showTab('seven')}>User-Friendly Interface</p>
                                <div style={{ overflow: 'hidden' }} className={showseven ? 'showtab' : 'hidetab'}><p className="main_para">Our platform is designed with usability in mind. Both your team and your customers will find our interface intuitive and easy to navigate, leading to a more pleasant and efficient support experience.</p></div>

                            </div>
                            <div className="tab_main" style={{ overflow: 'hidden', border: showeight && 0 }}>
                                <p onClick={() => showTab('eight')}>Proactive Support</p>
                                <div style={{ overflow: 'hidden' }} className={showeight ? 'showtab' : 'hidetab'}><p className="main_para">At CurvyEgg, we don’t just react to issues – we anticipate them. Our proactive approach to customer support helps identify potential problems before they escalate, ensuring a smoother experience for your customers.</p></div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">

                        {/* <img data-aos="fade-up-left" className="top_clear" src={charts} width="88%" />   */}

                        <div data-aos="fade-down-right" className='index_whycurvy_cards_main' >
                            <div>
                                <Whycards color="#446beb" number="79%" text="Reduced Customer Support Costs" />
                            </div>
                            <div>
                                <Whycards number="17" sec />
                            </div>
                            <div>
                                <Whycards rating text="Agent Feedback" />
                            </div>
                            <div>
                                <Whycards number="1,000,000+" text="Chat Handled" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default WhyCurvy
