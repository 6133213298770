import React,{useEffect} from 'react'
import chat from '../../assets/Chat.png'
import support from '../../assets/svg/support.svg'
import sales from '../../assets/svg/sales.svg'
import './wehave.css'
import '../../assets/fonts/font.css'
import AnimateChat from '../AnimateChat/AnimateChat'

import Aos from 'aos'

function WeHave() {
    useEffect(
        ()=>(
            Aos.init({
                duration:2000,
                once:true
            })
        )
    )
    return (
        <div id="livechat" data-aos="fade-up" className="container we_main" style={{minheight:'768px',overflow:'hidden',paddingRight:'0' }}>
            <div className="left_first">
                <h3 className="left_first_head fontsforweb_fontid_9785">Marketing Strategy with AI Interactions
                </h3>
                <p className="left_first_para">Use AI conversational platforms to transform your marketing campaigns. CurvyEgg automates lead nurturing and streamline campaign processes, allowing your marketing team to focus on creative ideas and strategic innovations. This ensures that every campaign deeply resonates with your audience.</p>

            </div>
            <div className="right" >
                    {/* <img src={chat} width="434px" height="690px"/> */}
               
              <AnimateChat/>
            </div>
            <div className="left_sec" style={{position:'relative'}}>
                <a className="left_link" href="#"><img src={support} width="55px" style={{marginRight:'15px'}}/>Try Free</a>
               <div style={{position:'absolute',bottom:'100px',right:20}}> 
               <a className="left_link" href="#" ><img src={sales} width="55px"style={{marginRight:'15px'}}/>Any Help?</a></div>
                <p className="left_sec_para">and many more...</p>
            </div>

        </div>
    )
}

export default WeHave
