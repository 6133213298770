import './Register.css';
import { useEffect, useState } from 'react';
import { Signuplist } from './Signuplist';
import { Signupform } from './Signupform';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import OnlyFooter from '../../Components/OnlyFooter/OnlyFooter';
import { useLocation } from 'react-router-dom';

import supp1 from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-1.svg'
import supp2 from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-2.svg'
import supp3 from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-3.svg'
import supp4 from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-4.svg'
import faqicon from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-FAQ.svg'
import liveicon from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-Live Chat.svg'
import supp5 from '../../assets/svg/Support/CurvyEgg Support Page Icon Set SVG/Support-5.svg'

function Register(props) {
  const [headerprop, setHeaderprop] = useState(null)
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/signup') {
      setHeaderprop('signin')
    }
  }, [location])
  const [support_card_data, setSupport_card_data] = useState([
    {
      icon: supp1,
      text: 'Lorem Ipsum'
    },
    {
      icon: supp2,
      text: 'Lorem Ipsum'
    },
    {
      icon: supp3,
      text: 'Lorem Ipsum'
    },
    {
      icon: supp4,
      text: 'Ipsum Lorem'
    },
    {
      icon: supp5,
      text: 'Lorem Ipsum'
    }
  ])



  return (
    <div>
      <Header headerprop />
      <div class="container">
      
      
      <div class="signUp">
      <h2 class="home-h" >Sign Up</h2>

          <div class="col-lg-10 col-md-6 col-sm-12 mob-wid margin-top d-flex align-items-center justify-content-center">
            <Signupform />

          </div>
        </div>




        <div style={{ marginBottom: '40px' }}></div>

      </div>
    </div>
  );
}

export default Register;
