import React,{useState} from 'react'
import Card from '../Card/Card';
import './threecards.css'
import icon1 from '../../assets/svg/about-svg/product-01.svg';
import icon3 from '../../assets/svg/about-svg/FAQs-Assistance-01.svg';
import icon2 from '../../assets/svg/about-svg/custmor-support-01.svg';

const ThreeCards = (props) =>{

    let comm_style={}
    let coll_style={}
    let cont_style={}
    const [cardId,setCardId] = useState({
        communicate:false,
        collect:false,
        contribute:false
    })

    if(cardId.communicate){
        comm_style={
            width:'100%'
        }
        coll_style={
            width:'0px',
            opacity: '0',
            transition: 'width 0.3s linear ,opacity .1s linear'
        }
        cont_style={
            width:'0px',
            opacity: '0',
            transition: 'width 0.3s linear ,opacity .1s linear'
        }
    }
    else if(cardId.collect){
        comm_style={
            width:'0px',
            opacity: '0',
            transition: 'width 0.3s linear ,opacity .1s linear'
        }
        coll_style={
            width:'100%',
        }
        cont_style={
            width:'0px',
            opacity: '0',
            transition: 'width 0.3s linear ,opacity .1s linear'
        }
    }
    else if(cardId.contribute){
        comm_style={
            width:'0px',
            opacity: '0',
            transition: 'width 0.3s linear ,opacity .1s linear'
        }
        coll_style={
            width:'0px',
            opacity: '0',
            transition: 'width 0.3s linear ,opacity .1s linear'
         
        }
        cont_style={
            width:'100%',
        }
    }




    const [cardbtnclick,setCardbtnclick] = useState(false)
    const [cardData] = useState({
        one:{
            imgsrc:icon1,
            head:'Product Recommendations',
            para:`Utilize chatbots to suggest products in a friendly, conversational way based on the user's past searches, preferences, and purchase history.`
        },
        two:{
            imgsrc:icon2,
            head:'Customer Support',
            para:'Deploy intelligent bots to evaluate issues, streamline resolutions, enhance service quality, and efficiently automate routine processes'
        },
        three:{
            imgsrc:icon3,
            head:'FAQs Assistance',
            para:'Keep potential customers from getting lost on your website by having your bot answer FAQs instantly and accurately, enhancing their experience.'
        }
    });
    const [Commcardbtnclick,setCommCardbtnclick] = useState(false);
    const [Collcardbtnclick,setCollCardbtnclick] = useState(false);
    const [Contcardbtnclick,setContCardbtnclick] = useState(false)
    const showMoreDetail = (id)=>{
        if(id === 'communicate'){
            setCardId({
                collect:false,
                contribute:false,
                communicate:!cardId.communicate
            })
            setCommCardbtnclick(!Commcardbtnclick);

        }    
        else if(id === 'collect'){
            setCardId({
                collect:!cardId.collect,
                contribute:false,
                communicate:false
            })
            setCollCardbtnclick(!Collcardbtnclick);

        } 
        else if(id === 'contribute'){
            setCardId({
                collect:false,
                contribute:!cardId.contribute,
                communicate:false
            })

            setContCardbtnclick(!Contcardbtnclick);
        } 
        console.log('clicked')
      
        
       }


         
    return(
        <div className='threecardsmain' style={{width:'100%',margin:'50px 0px !important',paddingBottom:'20px'}}>
            
            <div style={comm_style} >
            <Card cardDetail={cardData.one} cardReadMore={()=>showMoreDetail('communicate')} btnClicked ={Commcardbtnclick} handleclose={()=>showMoreDetail('communicate')}/>

            </div>
           
           <div style={coll_style}>
                <Card cardDetail={cardData.two} cardReadMore={()=>showMoreDetail('collect')} btnClicked ={Collcardbtnclick}  handleclose={()=>showMoreDetail('collect')}/>
    
                </div> 
           
          <div style={cont_style}>
                <Card cardDetail={cardData.three} cardReadMore={()=>showMoreDetail('contribute')} btnClicked ={Contcardbtnclick}  handleclose={()=>showMoreDetail('contribute')}/>
    
                </div>
        </div>
    );
}

export default ThreeCards;