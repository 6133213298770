import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import { motion } from 'framer-motion'
import Policy from './policy'
import TandC from './TandC'
import Accessibility from './Accessibility'
import './termpolicy.css'
import PopupTerms from '../Popupterm/Popupterm'

import { useParams } from 'react-router-dom'
import Cookies from './cookies';

export function vakanda() {
    if (window.innerWidth > 992) {
        const marfa = document.getElementsByTagName('body');
        marfa[0].style = "overflow:hidden;"
    }
}

function varsa() {
    // if(window.innerWidth < 992)
    // {
    //    const cake = document.getElementsByTagName('body');
    //    const lake = document.getElementById('mainer_close');
    //    cake[0].style = "overflow: hidden;"
    //    lake.style = `max-height: ${lake.offsetHeight+130}px;`
    //    lake.style = "margin: 0;"
    //    lake.style = "overflow: scroll;";
    // }

}
function Termpolicy() {
    const { id } = useParams()
    const navigate = useNavigate();
    console.log(id, 'id')
    const [popupshow, setPopupshow] = useState(false);
    const onpopupimgclick = () => (setPopupshow(false))

    useEffect(() => {
        setTimeout(() => setPopupshow(false));
    }, [])
    const [termData, setTermData] = useState({
        conditions: {
            head: 'Terms & Conditions',
        },
        privacy: {
            head: 'Privacy Policy',
        },
        cookies: {
            head: 'Cookies Policy for CurvyEgg',
        }
    })
    const [activeterm, setActiveterm] = useState({
        conditions: id === 'conditions' ? true : false,
        privacy: id === 'privacy' ? true : false,
        cookies: id === 'cookies' ? true : false
    })
    const setTermactive = (id) => {
        if (id === 'conditions') {
            setActiveterm({ privacy: false, cookies: false, conditions: true })
            navigate("/term/conditions");
        }
        else if (id === 'privacy') {
            setActiveterm({ privacy: true, cookies: false, conditions: false })
            navigate("/term/privacy");
        }
        else if (id === 'cookies') {
            setActiveterm({ privacy: false, cookies: true, conditions: false })
            navigate("/term/cookies");

        }

    }

    return (
        <div>

            {popupshow ? <PopupTerms setPopupshow={setPopupshow} /> : null}
            <div className="termpage" id="arslan_easter_egg">
                <Header headercrossicon={popupshow} onpopupimgclick={onpopupimgclick} />
                {!popupshow && <>
                    <div style={{ objectFit: 'contain' }}>


                    </div>
                    <div className="container termtop" style={{ marginTop: '80px' }}>
                        <div className="text-center mx-auto">
                            <div className="btn-group" role="group">
                                <div onClick={() => setTermactive('conditions')} className={activeterm.conditions ? "btngroup btn1 btn_clicked" : "btngroup btn1"}>Terms & Conditions</div>
                                <div onClick={() => setTermactive('privacy')} className={activeterm.privacy ? "btngroup btn2 btn_clicked" : "btngroup btn2"}>Privacy Policy</div>
                                <div onClick={() => setTermactive('cookies')} className={activeterm.cookies ? "btngroup btn3 btn_clicked" : "btngroup btn3"}>Cookies Policy</div>
                            </div>
                        </div>
                        <br />
                        <br />
                        {/*  <p className="somethingText">Something Like</p>*/}
                        <div className="container-fluid">
                            <div className='container'>
                                {activeterm.conditions && <motion.h3 className="termhead" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{termData.conditions.head}</motion.h3>}

                                {activeterm.privacy && <motion.h3 className="termhead" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{termData.privacy.head}</motion.h3>}

                                {activeterm.cookies && <motion.h3 className="termhead" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>{termData.cookies.head}</motion.h3>}
                            </div>
                        </div>
                        {activeterm.conditions && <TandC />}

                        {activeterm.privacy && <Policy />}

                        {activeterm.cookies && <Cookies />}

                        {/*{activeterm.accessibility && <motion.h3 className="termhead" initial={{ opacity: 0 }} animate={{ opacity: 1 }} >{termData.accessibility.head}</motion.h3>}
                        {activeterm.service && termData.service.text}
                        {activeterm.privacy && termData.privacy.text}
                        {activeterm.accessibility && termData.accessibility.text}*/}
                        {/*<div class="submitbtn">
                            <button type="button" class="btnone">Decline</button>
                            <button type="button" class="btntwo">Accept</button>
                        </div>*/}
                    </div>
                </>}
            </div>
        </div>
    )
}

export default Termpolicy
