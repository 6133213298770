import React, { useState, useEffect } from 'react'
import '../../assets/fonts/font.css'

import link from '../../assets/link.png'
import link1 from '../../assets/link1.png'
import link2 from '../../assets/link2.png'
import link3 from '../../assets/link3.png'
import Aos from 'aos'
import { motion } from 'framer-motion'
import './sellsol.css'
function SellSol() {
        useEffect(
                () => (
                        Aos.init({
                                duration: 2000,
                                once: true
                        })
                )
        )
        const [sellText, setSellText] = useState({
                id: 'live',
                text: 'Equip your sales team with tools to generate and qualify leads, boost sales, and enhance the customer experience. Our AI chat functionality is specifically designed for e-commerce platforms, ensuring seamless and effective interactions.'
        })

        const sellingText = (ids) => {
                if (ids === 'live') {
                        setSellText({ id: 'live', text: 'Equip your sales team with tools to generate and qualify leads, boost sales, and enhance the customer experience. Our AI chat functionality is specifically designed for e-commerce platforms, ensuring seamless and effective interactions.' })
                }
                else if (ids === 'technical') {
                        setSellText({ id: 'technical', text: 'Boost customer satisfaction and drive repeat business by providing 24/7 support across multiple channels. Offer timely assistance through live chat, email, and social media to address customer needs quickly and effectively' })
                }
                else setSellText({ id: 'ecommerce', text: 'Utilize an AI chatbot to answer customer questions, guide them through your offerings, and address concerns in real-time. By engaging visitors effectively, you can enhance their experience, reduce bounce rates, and increase conversions, all while providing instant support and building trust.' })
        }
        return (
                <div id="solution" data-aos="fade-up" className="sellsol_top">
                        <h3 className="sellsol_main_head center ">Transforming AI with a Human Touch</h3>
                        < p className="sellsol_main_para center "> We create AI that combines technology with a human touch.</p>
                        <div className="container">
                                <div className="hideMobile">
                                        <div className="row" style={{ marginTop: '50px' }}>
                                                <div data-aos="fade-right" className="col-md-5 col-sm-12">
                                                        {sellText.id === 'live' ? <motion.p className="sellsol_main_des"
                                                                animate={{
                                                                        opacity: 1,
                                                                        x: 0
                                                                }}
                                                                initial={{
                                                                        opacity: 0,

                                                                }}
                                                        >{sellText.text} </motion.p> : null}
                                                        {sellText.id === 'technical' ? <motion.p className="sellsol_main_des"
                                                                animate={{
                                                                        opacity: 1,

                                                                }}
                                                                initial={{
                                                                        opacity: 0,

                                                                }}
                                                        >{sellText.text} </motion.p> : null}
                                                        {sellText.id === 'ecommerce' ? <motion.p className="sellsol_main_des"
                                                                animate={{
                                                                        opacity: 1,

                                                                }}
                                                                initial={{
                                                                        opacity: 0,

                                                                }}
                                                        >{sellText.text} </motion.p> : null}


                                                </div>
                                                <div data-aos="fade-left" className="col-md-7 col-sm-12 gridd" style={{ marginTop: '20px' }}>
                                                        {/* <img src={round} width="90%" style={{marginLeft:'20px'}}/> */}
                                                        <div className="" style={{ marginLeft: '30px' }}>
                                                                <img src={link} width='232px' height='278px' />
                                                        </div>
                                                        <div className="iconBtn">
                                                                <div className="first">
                                                                        <img src={link1} width='96px' height='96px' />
                                                                        <button className={sellText.id === 'live' ? 'btnactive' : null} style={{ border: 'none' }} onClick={() => sellingText('live')}>Boost Sales</button>
                                                                </div>
                                                                <div className="sec">
                                                                        <img src={link2} width='96px' height='96px' />
                                                                        <button className={sellText.id === 'technical' ? 'btnactive' : null} style={{ background: '#483a9c', color: 'white', border: 'none' }} onClick={() => sellingText('technical')}>Customer Support</button>
                                                                </div>
                                                                <div className="three">
                                                                        <img src={link3} width='96px' height='96px' />
                                                                        <button className={sellText.id === 'ecommerce' ? 'btnactive' : null} style={{ background: '#446beb', border: 'none' }} onClick={() => sellingText('ecommerce')}>Engage Visitors</button>
                                                                </div>

                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className="hideDesktop selsol_mob_main">
                                        <div data-aos="fade-up" className="live_chat_mob">
                                                <img src={link1} width='80px' height="80px" />
                                                <div style={{ background: '#0a021d', color: 'white' }}>Boost Sales</div>
                                                <div>
                                                        <p>
                                                                Equip your sales team with tools to generate and qualify leads, boost sales, and enhance the customer experience. Our AI chat functionality is specifically designed for e-commerce platforms, ensuring seamless and effective interactions.
                                                        </p>
                                                </div>

                                        </div>
                                        <div data-aos="fade-up" className="tech_sup_mob">
                                                <img src={link2} width='80px' height='80px' />
                                                <div style={{ background: '#483a9c', color: 'white' }}>Customer Support</div>
                                                <div>
                                                        <p>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id  </p>
                                                </div>

                                        </div>
                                        <div data-aos="fade-up" className="ecomm_mob">
                                                <img src={link3} width='80px' height='80px' />
                                                <div style={{ background: '#446beb', color: 'white' }}>Engage Visitors</div>
                                                <div>
                                                        <p>
                                                                ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci</p>
                                                </div>

                                        </div>
                                </div>

                        </div>
                </div>
        )
}

export default SellSol
