import React,{useEffect} from 'react'
import './integrate.css'
import '../../assets/fonts/font.css'
import cpanel from  '../../assets/cpanel.png'
import Aos from 'aos'
import Sixsvgcomb from '../../UI/sixsvgcomb/Sixsvgcomb'
function Integrate() {
    useEffect(
        ()=>(
            Aos.init({
                duration:2000,
                once:true
            })
        )
    )
    return (
        <div data-aos="fade-up" className="integrate_top">
            <div className="container">
            <h3 className="integrate_main_head">Integration for Your Preferred Platform</h3>
                <div className="dsflex">
                    <div className="first_half">
                       <p className="integrate_main_para">Integrating chatbots with Shopify, WordPress, Magento, and Joomla significantly enhances customer engagement by automating product recommendations, answering FAQs, and providing real-time support, leading to improved customer satisfaction and higher conversion rates.
                       </p>
                    </div>
                    <div className="sec_half">
                        {/* <img className="integrate_img" src={cpanel} width="90%"/> */}
                        <Sixsvgcomb/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Integrate
