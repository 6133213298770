import React, { useState, useEffect } from 'react'
import './Ataglance.css'
import '../SellSol/sellsol.css'
import '../../assets/fonts/font.css'
import { IoIosChatbubbles } from "react-icons/io";
import visitor1 from '../../assets/svg/atGlance/Enterprise Conversational AI Space-01.svg'
import visitor2 from '../../assets/svg/atGlance/Enterprise Conversational AI Space-01.svg'

import livechat1 from '../../assets/svg/atGlance/Conversations Automated-01.svg'
import livechat2 from '../../assets/svg/atGlance/Conversations Automated-01.svg'

import dashboard1 from '../../assets/svg/atGlance/Collaborated With Globally-01.svg'
import dashboard2 from '../../assets/svg/atGlance/Collaborated With Globally-01.svg'

import Aos from 'aos'
import { motion } from 'framer-motion'

import { Link } from 'react-router-dom'
import leftarrow from "../../assets/svg/left-arrow.svg"

// import downarrow from '../../assets/svg/Arrow Up.svg'
import downarrow from '../../assets/svg/Cross icon/Arrow upblue.svg'
function Ataglance() {
    useEffect(
        () => (
            Aos.init({
                duration: 2000,
                once: true
            })
        )
    )

    const [showData, setShowData] = useState({
        showlivechat: false,
        showdashboard: false,
        showvisitor: false
    })

    const checkShow = (id) => {
        if (id === 'dashboard') {
            setShowData({
                showlivechat: false,
                showdashboard: !showData.showdashboard,
                showvisitor: false

            })
        }
        if (id === 'livechat') {
            setShowData({
                showlivechat: !showData.showlivechat,
                showdashboard: false,
                showvisitor: false

            })
        }
        if (id === 'visitor') {
            setShowData({
                showlivechat: false,
                showdashboard: false,
                showvisitor: !showData.showvisitor

            })
        }
    }
    const glanceData = (<div>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>           
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
    </div>)

    let DashboardData = (<div>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">this is change text</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">this is change text</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">this is change text</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>           <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
        <span className="glancetext">24/7 live agents</span>
    </div>)
    let visitorData = (
        <div>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">hello change text</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">hello change text</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">hello change text</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">hello change text</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">hello change text</span>           <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
            <span className="glancetext">24/7 live agents</span>
        </div>
    )
    return (
        <div data-aos="fade-up" className="glance_top">
            <div className="container">
                <div className="row">
                    <h3 className="glance_main_head">Our Milestones in Numbers</h3>
                    < p className="glance_main_para"> We have a rich AI history, partnering with top global brands and automating millions of conversations.</p>
                    <div className='dividernoneonweb' style={{ width: '90px', height: '2px', background: 'lightgrey', marginLeft: '35%', marginTop: '20px' }}></div>
                </div>
                <div className="flexd" >
                    <div className="svgText_main">
                        <div className='leftarrowmobhide' style={{ width: '10px', marginLeft: '-200px' }}>
                            <img src={leftarrow} style={{ marginLeft: '150px', marginTop: '30px' }} width="170px" />
                        </div>
                        <div>
                            {/* svg */}
                            <div style={{ width: "80%", margin: "10px auto 0px", textAlign: "center" }}>
                                <div onClick={() => checkShow('livechat')} class="icon-container">
                                    <img src={showData.showlivechat ? livechat2 : livechat1} width="90px" />
                                </div>
                                {/* text */}
                                <h5 className="glancesubtext">3+ Years
                                In Conversational AI
                                
                                </h5>
                            </div>
                            {showData.showlivechat ? <img src={downarrow} className='glancyicon' width="20px" /> : null}
                        </div>
                        <div>
                            {/* svg */}
                            <div style={{ width: "80%", margin: "10px auto 0px", textAlign: "center" }}>
                                <div onClick={() => checkShow('dashboard')} class="icon-container">
                                    <img src={showData.showdashboard ? dashboard2 : dashboard1} width="90px" />
                                </div>
                                {/* text */}
                                <h5 className="glancesubtext">50+ 
                                Global Brands
                                
                                </h5>
                            </div>
                            {showData.showdashboard ? <img src={downarrow} className='glancyicon' width="20px" /> : null}
                        </div>
                        <div>
                            {/* svg */}
                            <div style={{ width: "80%", margin: "10px auto 0px", textAlign: "center" }}>
                                <div onClick={() => checkShow('visitor')} class="icon-container" style={{ cursor: 'pointer' }} >
                                    <img src={showData.showvisitor ? visitor2 : visitor1} width="90px" />
                                </div>
                                {/* text */}
                                <h5 className="glancesubtext">5M+
                                Automated Conversations
                                
                                </h5>
                            </div>
                            {showData.showvisitor ? <img src={downarrow} className='glancyicon' width="20px" /> : null}
                        </div>

                    </div>
                    {showData.showlivechat ? <motion.div className="info_card" style={{ width: '90%' }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                        initial={{
                            opacity: 0,
                            y: '-50px'
                        }}
                    >
                        {glanceData}
                        <Link to="/glance/live" className="glanceinside">read more...</Link>
                    </motion.div> : null}
                    {showData.showdashboard ? <motion.div className="info_card" style={{ width: '90%' }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                        initial={{
                            opacity: 0,
                            y: '-50px'
                        }}
                    >
                        {DashboardData}
                        <Link to="/glance/dashboard" className="glanceinside">read more...</Link>
                    </motion.div> : null}
                    {showData.showvisitor ? <motion.div className="info_card" style={{ width: '90%' }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                        initial={{
                            opacity: 0,
                            y: '-50px'
                        }}
                    >
                        {visitorData}
                        <Link to="/glance/visitor" className="glanceinside">read more...</Link>
                    </motion.div> : null}
                </div>
            </div>
        </div>
    )
}

export default Ataglance
