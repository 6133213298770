import React from 'react'
import './allinoneCSS.css'
const TermsandConditions = () => {
    return (
        <div>
            <div>
                <div className='container-fluid'>
                    <div className="container">
                        <p>These Terms & Conditions govern the use of the platform or suite which transforms a website into conversational experiences by creating and using a chatbot, as well as analyzing the data obtained through the chatbot (hereinafter, “the Service”). The Service includes chatbots that can also be used on main messaging channels. One of the aims of this Service is to obtain higher conversion rates and a unique experience for the visitors.
                        </p>
                        <p>By accepting the terms and conditions of our service, you expressly consent to be contacted by us through various means of communication, including but not limited to telephone, email, and chat, for the purpose of providing you with relevant information about our products or services, as well as to address any inquiries or requests you may have.
                        </p>
                        <p>However, we recognize your right to manage your communication preferences with us. If at any time you wish to unsubscribe from our communications or restrict the way we communicate with you, you may do so by accessing your account settings and updating your preferences. We commit to processing your request in a reasonable timeframe and respecting your choice regarding the receipt of our communications.

                        </p>
                        <p>CurvyEgg reserves the right to modify these Terms & Conditions at any time, as well as any other applicable conditions, by informing the Client. The Client shall have the right to terminate his contract if he does not agree with the new conditions.</p>

                        <h1 className='conditions'>Conditions</h1>
                        <h2>Provision of Service and Grant of License</h2>
                        <p className='italic'>Subject to the terms and conditions contained herein, CurvyEgg grants the Client a non-exclusive, revocable, and non-transferable right to a license to use the Service. By means of this Service, the Client will access a subscription to CurvyEgg that will allow to:</p>

                        <p>
                            <ul>
                                <li>Manage all customer data collected by the chatbots, analyze and export performance metrics according to the data collected.</li>
                                <li>Control conversations with end users as live agents from the Chats section.</li>
                                <li>Manage all integrations from the Integrations section.</li>
                                <li>CurvyEgg reserves the right to unilaterally modify at any time and without prior notice the design, presentation, and configuration, as well as some or all functionalities of its platform, and/or to add new functionalities.</li>
                            </ul>

                        </p>
                        <h2 className=''> Duration</h2>
                        <p className=''>
                            These Terms & Conditions shall remain in force for the period of time contracted by the Client in the specific terms and conditions. The Service will be tacitly and indefinitely extended for equal consecutive periods, unless either of the Parties opposes it by convincingly communicating it to the other with a minimum notice of two months before the start of the corresponding extension period. In the event that the Client decides not to renew, such notification shall be made by email to its Customer Success Manager.
                        </p>
                        <h2 className=''>Responsibility</h2>
                        <p className=''>
                            <p className='italic'>
                                The Client will indemnify, defend, and protect CurvyEgg against any liability, damage, or cost derived from a third-party claim, regarding:

                            </p>
                            <p>
                                <ul>
                                    <li>The Client's data and third party’s data collected by CurvyEgg on the Client’s behalf.</li>
                                    <li>The use of the Services by the Client, or users authorized by it, breaching these Terms & Conditions or the specific terms and conditions, based on good faith and according to the Law.</li>
                                    <li>The use of the Services by end users.</li>
                                </ul>

                            </p>
                            <p>CurvyEgg will only be liable for malicious and direct damages suffered by the Client, excluding lost profits, only if they have been caused directly by CurvyEgg, pursuant to a final judgment. The economic responsibility of CurvyEgg will in any case be limited to the amount corresponding to the price actually paid by the Client for the last six-month period corresponding to the Services that caused the claim, pursuant to a final judgment</p>

                        </p>

                        <h2>Guarantee of the Service</h2>
                        <p>
                            <p>CurvyEgg offers a 99% uptime guarantee for the Client, considered by the 24h period. CurvyEgg may compensate the Client for interruptions of the Services in an amount equal to one day's value of the price paid by the Client, multiplied by each 24 hour period in which the Client experiences the interruption of the Services.</p>

                            <p>However, if the Services are interrupted for a period inferior to 24h, for a reasonable cause, to be understood according to good faith and best practices, this compensation will not be due.</p>
                            <p>CurvyEgg will periodically measure the availability of the Services. CurvyEgg reserves the right to periodically change the measurement points and the methodologies used without notifying the Client.</p>
                            <p>In the event that CurvyEgg detects or becomes aware of an interruption of the Services, it will send interruption reports and periodic situation reports to the Client, with all the necessary information.</p>
                            <p>
                                In the event that the Client makes a claim related to the availability of the Services, and CurvyEgg determines that there was no interruption of the Services, CurvyEgg will provide the Client with the relevant information to support this determination. Curvyegg will make every effort to send Client a notification of scheduled maintenance as follows:
                                <ul>
                                    <li>14 days before scheduled maintenance.</li>
                                    <li>Up to 2 hours before emergency maintenance.</li>
                                </ul>

                            </p>
                        </p>

                        <h2> Intellectual Property Rights</h2>
                        <p>
                            <p>
                                CurvyEgg reserves all intellectual property rights over its methodology, models, and software. CurvyEgg declares that it has the appropriate authorizations and licenses to grant the software licenses, it assumes that it will be its exclusive account the payment of any other intellectual property rights and the payment of rights and compensation for such concepts. Likewise, the Client acknowledges the intellectual property of CurvyEgg on the methodology, models, and software for the provision of the Services to the Client, not acquiring any intellectual property rights on the software or on the methodology and model.
                            </p>
                            <p>
                                Intellectual property rights, including reproduction, distribution, public communication, and/or transformation, in any type of support or mode of exploitation, and industrial are reserved in favor of CurvyEgg, exclusively and worldwide, without limitation in time, that could be derived from the object of the provision of the Services agreed upon in these Terms & Conditions, including in any case the software. Consequently, the Client may not directly or indirectly dispose of the aforementioned software, for a use other than that specified in these Terms & Conditions and/or the specific terms and conditions.
                            </p>
                            <p>
                                Intellectual property rights are reserved in favor of the Client, including the reproduction, distribution, public communication, and/or transformation, in any type of support or exploitation modality, and industrial that could be derived from all the contents provided to CurvyEgg by the Client entered into the software. Consequently, CurvyEgg may not directly or indirectly dispose of any of the content or materials for use other than that specified in these Terms & Conditions and/or the specific terms and conditions.
                            </p>
                        </p>
                        <h2>Data Protection Information Clauses</h2>
                        <p>
                            <p className='italic'>
                                CurvyEgg is the data controller of the following data: The professional location data (contact data and data related to the function or position held) of the natural persons who, providing their services to the Client, come into contact with CurvyEgg to enable the maintenance of the business relationship formalized through this Contract ("Contact Persons"), to whom the Client undertakes to transfer the full content of this clause. The personal data of the above-mentioned data subjects will be processed for the following purposes:

                            </p>
                            <p>
                                <ul>
                                    <li>
                                        To maintain, develop, and manage the business relationship outlined in these Terms & Conditions, data will be processed and stored as long as the relationship exists. Once terminated, data will be retained according to legal requirements. The legal basis for processing this data is CurvyEgg's legitimate interest in maintaining the business relationship with the Client through their Contact Persons.
                                    </li>
                                    <li>
                                        CurvyEgg may share data with Public Administrations to meet legal obligations, with State Security Forces, Courts, and Tribunals during investigations, and with banks for managing payments. Data may also be shared with providers of electronic communications, office automation, hosting, housing, computer maintenance, management, accounting, auditing, consulting, and legal representation. Some providers may be outside the European Economic Area, in which case appropriate data protection safeguards will be in place.
                                    </li>
                                    <li>
                                        Data subjects have rights to access, rectify, erase, restrict processing, port data, object, and not be subject to automated decisions, including profiling, which significantly affects them. They can withdraw consent at any time without affecting prior processing. Requests can be sent to CurvyEgg, and complaints can be lodged with the relevant supervisory authority if needed.
                                    </li>
                                </ul>

                            </p>
                        </p>


                        <h2> Data Processing Clause by CurvyEgg</h2>
                        <p className='sub'>
                            <span className='fs-5 fw-bold'>Purpose: </span>
                            <span>The purpose of this clause is to regulate the relationship between the Client, in its capacity as Data Controller, and CurvyEgg, in its capacity as Data Processor. The Processor undertakes to process the personal data only in accordance with the instructions provided by the Client and guarantees that the persons authorized to process the personal data undertake to respect confidentiality or shall be subject to confidentiality by statutory obligation.</span>
                        </p>
                        <p className='sub'>
                            <span className='fs-5 fw-bold'>Services and Duration: </span>
                            <span>The term of this clause shall be equal to the duration of the provision of the Services contracted by the Client to CurvyEgg.</span>
                        </p>
                        <p className='sub'>
                            <span className='fs-5 fw-bold'>Services and Duration: </span>
                            <span>The processing of personal data shall be carried out solely and exclusively for the purpose of providing the Services.</span>
                        </p>
                        <p className='sub'>
                            <span className='fs-5 fw-bold'>Type of Personal Data and Categories of Data Subjects: </span>
                            <span>The type of data to be processed are identification, personal characteristics, and commercial information data. The category of data subjects are clients.
                            </span>
                        </p>
                        <p className='sub'>
                            <span className='fs-5 fw-bold'>Obligations and Rights of the Client (Controller):
                            </span>
                            <ul>
                                <li>Provide or allow the Processor access to the data in order to be able to provide the Services;</li>
                                <li>Carry out a data protection impact assessment of the processing activities to be carried out by the Processor, where applicable;</li>
                                <li>Carry out the appropriate prior consultations;</li>
                                <li>Ensure, prior to and throughout the processing, the Processor's compliance with the Regulations;</li>
                                <li>Supervise the processing, including the performance of inspections and audits.</li>
                            </ul>
                        </p>
                        <p className='sub'>
                            <span className='fs-5 fw-bold'>Artificial Intelligence Services: </span>
                            <span> CurvyEgg informs that it will use the end-user data entered in the chatbots that use the following services, Build-it-for-me, FAQs AI Assistant, Lead Gen AI Assistant, and Appointment Scheduling Assistant, in an anonymized manner, to carry out training actions and enhancing the performance of these services through the use of artificial intelligence.
                            </span>
                        </p>

                        <h2>  Resolution</h2>
                        <span className='italic'>The parties agree that these Terms & Conditions may be terminated for the following reasons:</span>
                        <p>
                            <ul>
                                <li>
                                    By the will of any of the Parties, when there is a serious breach of the obligations agreed upon in these Terms & Conditions or the specific terms and conditions by the other party, especially due to non-compliance with payment within the relevant period. In any case, and as a prerequisite for the termination of the agreement by either of the parties, the non-compliant party must be notified of the non-compliance and given a minimum period of 15 days from the notification to remedy said non-compliance. After this period, the agreement may be terminated. In this case, the breach of any of the obligations may give the right of compensation for the damages suffered.
                                </li>
                                <li>
                                    Due to the extinction of the legal personality of any of the parties.
                                </li>
                                <li>
                                    By written agreement adopted by mutual agreement by both parties.
                                </li>
                            </ul>
                        </p>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default TermsandConditions