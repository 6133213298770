import React, { useState, useEffect } from 'react'
import Header from '../../Components/Header/Header'
import FAQ from '../../Components/FAQ/FAQ'
import Subform from '../../Components/Subform/Subform'
import Footer from '../../Components/Footer/Footer'
import CardGlance from '../../UI/CardGlance/CardGlance'
import { useParams } from 'react-router-dom'

import { Container } from 'react-bootstrap'
import downarrow from '../../assets/svg/Cross icon/Arrow upblue.svg'

import livelogo from '../../assets/svg/New_features/LiveChat.svg'
import dashboard from '../../assets/svg/New_features/Dashboard.svg'
import visitors from '../../assets/svg/New_features/VisitorsTeam.svg'
import sprinkles from '../../assets/sprinkles_blue.svg'
import './glance.css'
import Aos from 'aos'
import { motion } from 'framer-motion'
function Glance() {
    useEffect(
        () => (Aos.init({
            duration: 2000,
            once: true
        }))
    )
    let { id } = useParams();
    const [showcard, setShowcard] = useState('live')
    let color = '';
    if (id === 'live') {
        color = {
            live: true,
            dashboard: false,
            visitors: false
        }
    }
    else if (id === 'dashboard') {
        color = {
            live: false,
            dashboard: true,
            visitors: false
        }
    }
    else if (id === 'visitor') {
        color = {
            live: false,
            dashboard: false,
            visitors: true
        }
    }
    const [customClass, setCustomClass] = useState({
        live: true,
        dashboard: false,
        visitors: false
    });


    const selectCard = (ids) => {
        setShowcard(ids)
        if (ids === 'live') {
            setCustomClass({
                live: true,
                dashboard: false,
                visitors: false
            })
        }
        else if (ids === 'dashboard') {
            setCustomClass({
                live: false,
                dashboard: true,
                visitors: false
            })
        }
        else if (ids === 'visitor') {
            setCustomClass({
                live: false,
                dashboard: false,
                visitors: true
            })
        }
    }

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }} className="king" id='glancee'>
            <img className='glancesprinkles' src={sprinkles} />
            <img className='glancesprinkles' src={sprinkles} />
            <Header />
            <Container>
                <section className="flexRow">
                    <div className="glance_left">
                        <h3 className="glance_head">All Features at a Glance</h3>
                        <span></span>
                        <p className="glance_subhead">
                            It's about achieving your business goals and making them a reality.
                        </p>
                    </div>
                    <div className="flexCard">

                        <div><div onClick={() => selectCard('live')}><CardGlance imgsrc={livelogo} text="Custom Chat Widget" actClass={customClass.live} /></div>
                            {showcard === 'live' ? <img className='glancearrowicon' src={downarrow} width="20px" /> : null}
                        </div>
                        <div><div onClick={() => selectCard('dashboard')}><CardGlance imgsrc={dashboard} text="Analytics & Reports" actClass={customClass.dashboard} /></div>
                            {showcard === 'dashboard' ? <img className='glancearrowicon' src={downarrow} width="20px" /> : null}
                        </div>
                        <div><div onClick={() => selectCard('visitor')}><CardGlance visible imgsrc={visitors} text="Sales Integration" actClass={customClass.visitors} /></div>
                            {showcard === 'visitor' ? <img className='glancearrowicon' src={downarrow} width="20px" /> : null}
                        </div>
                    </div>

                </section>
                <motion.section className="sec_section"
                    animate={{
                        y: 0,
                        opacity: 1
                    }}
                    initial={{
                        y: '-10px',
                        opacity: 0
                    }}
                >
                    <div className="list">
                        <ul>
                            <li>Real-Time Communication</li>
                            <li>Customizable Interface</li>
                            <li>File Sharing</li>
                            <li> Chat Transcripts</li>
                            <li> Multi-Channel Integration</li>
                            <li> Queue Management</li>
                            <li> 24/7 Availability</li>
                            <li>   Natural Language Processing</li>
                            <li>  Automated Responses</li>
                            <li>  Personalization</li>
                            <li>  Data Collection</li>
                            <li>  Integration with CRM</li>
                        </ul>
                    </div>
                    <div className="glance_para">
                        <li style={{ listStyle: "none", marginTop: "8px" }}>Instant messaging with customers for immediate support.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}>Personalize the chat widget to match your brand.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}>Share documents, images, and other files directly within the chat.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}>Save and review past conversations for quality assurance
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}>Integrate with social media, email, and other platforms.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Efficiently manage multiple chat requests.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Provide 24/7 assistance without human intervention.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Understand and respond to customer inquiries in a conversational manner.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Handle common questions and tasks automatically.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Tailor interactions based on user data and behavior.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Gather customer information and preferences for better service.
                        </li>
                        <li style={{ listStyle: "none", marginTop: "10px" }}> Sync with customer relationship management systems for seamless service.
                        </li>


                    </div>
                </motion.section>
            </Container>

            <FAQ />
            <Subform />
        </div>
    )
}

export default Glance
